/* You can add global styles to this file, and also import other style files */
html, body, app-root {
    height: 100%;
  }
  body {
    font-family: Lato;
    margin-bottom: 0;
    margin-top: 0;
  }

.main-app-container {
    height: 100%;
    display: flex;
    flex-direction: column;
}

app-footer {
    margin-top: auto;
}

app-home{
  height: 100%;
  background: fixed;
  background: #f5f5f5;
}
